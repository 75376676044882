<template>
  <div>
    <z-header></z-header>
    <div class="min_width banner_image_box">
      <img src="../assets/img_banner_b.png" alt="" />
    </div>

    <div class="section_box service_introduction">
      <div class="page_center">
        <div class="page_title">SEO整站优化服务介绍 · SERVICE INTRODUCTION</div>
        <p class="desc">
          整站优化是网站SEO优化的应用，是更高层次的网站营销策略，是以提升网站搜索引擎友好度为核心通过提升网站的质量，获得更好的排序权重和搜索流量，并为企业站的营销盈利服务。一般有两种形式的整站优化服务，一种是以关键词排名为考核依据，一种是以流量为考核依据。
        </p>
      </div>
      <!-- <div class="circular circular1"></div> -->
    </div>
    <div class="section_box four_stages">
      <div class="page_center page_title">整站优化四大阶段 · FOUR STAGES</div>
      <div class="page_center stages_box">
        <div class="stages_item">
          <img src="../assets/ic_service_d.png" alt="" class="stages_image" />
          <div class="title">第一阶段：网站诊断</div>
          <p class="desc">
            网站诊断，站点全面分析分析竞争对手的网站情况网站优化方针确定与部署
          </p>
        </div>
        <div class="stages_item">
          <img src="../assets/ic_service_c.png" alt="" class="stages_image" />
          <div class="title">第二阶段：优化前期</div>
          <p class="desc">
            整站布局，关键词分析挖掘内容强化，建立网站内容库内部优化，URL与代码优化
          </p>
        </div>
        <div class="stages_item">
          <img src="../assets/ic_service_b.png" alt="" class="stages_image" />
          <div class="title">第三阶段：优化中期</div>
          <p class="desc">
            丰富网站内容生态，增加收录页面架构优化，强大外链提权相关词、长尾词无限拓展
          </p>
        </div>
        <div class="stages_item">
          <img src="../assets/ic_service_a.png" alt="" class="stages_image" />
          <div class="title">第四阶段：优化后期</div>
          <p class="desc">关键词排名上升迅猛网站权重快速提升出现大量长尾精准</p>
        </div>
      </div>
    </div>
    <div class="section_box annual_package">
      <div class="page_center page_title">
        SEO整站优化包年套餐 · ANNUAL PACKAGE
      </div>
      <div class="page_center set_meal_box">
        <div class="box_body">
          <div class="box_col">
            <div class="item header">套餐版本</div>
            <div class="item version">标准套餐</div>
            <div class="item version">精品套餐（热销）</div>
            <div class="item version">高级套餐（对网站有要求）</div>
            <div class="item version">个性定制套餐（N＞50）</div>
          </div>
          <div class="box_col">
            <div class="item header">关键词</div>
            <div class="item">核心词：10个</div>
            <div class="item">推荐词：10个</div>
            <div class="item">核心词：10个</div>
            <div class="item">推荐词：10个</div>
            <div class="item">核心词：10个</div>
            <div class="item">推荐词：10个</div>
            <div class="item">核心词：10个</div>
            <div class="item">推荐词：10个</div>
          </div>
          <div class="box_col">
            <div class="item header">承诺达标</div>
            <div class="item">60%上词率</div>
            <div class="item">6</div>
            <div class="item">60%上词率</div>
            <div class="item">6</div>
            <div class="item">60%上词率</div>
            <div class="item">6</div>
            <div class="item">60%上词率</div>
            <div class="item">6</div>
          </div>
          <div class="box_col">
            <div class="item header">价格咨询</div>
            <div class="item price">
              <button class="btn">立即咨询</button>
            </div>
          </div>
        </div>

        <div class="box_footer">
          <div class="item">
            360、搜狗、移动端等排名效果免费赠送网站SEO诊断，提供整站专业优化方案
          </div>
        </div>
      </div>
    </div>
    <div class="section_box success_case">
      <div class="page_center page_title">整站优化成功案例 · SUCCESS CASE</div>
      <div class="page_center case_list">
        <div class="swiper_case_box">
          <swiper class="swiper_case" :options="caseSwiperOption">
            <swiper-slide v-for="(item, index) of 4" :key="index">
              <div class="case_item">
                <div class="case_name">腾创互联官网</div>
                <div class="word_box">
                  <div class="word_title">主词：</div>
                  <div class="word_list">
                    <span class="word_item">网站建设</span>
                    <span class="word_item">网站制作</span>
                    <span class="word_item">深圳seo</span>
                    <span class="word_item">高端网站建设</span>
                    <span class="word_item">深圳优化</span>
                  </div>
                </div>
                <div class="word_box">
                  <div class="word_title">推荐词：</div>
                  <div class="word_list">
                    <span class="word_item">深圳网站设计</span>
                    <span class="word_item">网站建设公司</span>
                    <span class="word_item">深圳网络优化</span>
                    <span class="word_item">深圳网站制作</span>
                    <span class="word_item">深圳网站制作</span>
                    <span class="word_item">深圳网站制作</span>
                  </div>
                </div>

                <div class="item_footer">
                  <div class="rate">达标率：<span class="red">100%</span></div>
                  <div class="more">
                    查看详情<span class="arrow_icon"></span>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="case-swiper-button case-swiper-button-prev"></div>
          <div class="case-swiper-button case-swiper-button-next"></div>
        </div>
      </div>
    </div>
    <div class="page_center section_box meal_interpret">
      <div class="interpret_box">
        <div class="meal_item">
          <p class="title">精品套餐(热销)解读：</p>
          <p class="desc">
            客户自选15个主关键词，云天下优化师推荐15个关键词，保证60%的上词率承诺排名效果以百度为准360搜索、搜狗搜索排名效果免费赠送。
          </p>
        </div>
        <div class="meal_item">
          <p class="title">高端套餐(对网站有要求)解读：</p>
          <p class="desc">
            选择高端套餐对网站有要求，网站百度收录量超过100条，网站有基础权重，建站时间超过3个月等。如果上面方案均不能满足您的需求，我们还可以根据实际情况，为您量身打造合理的个性化优化方案。这个收费需求根据实际情况而定。
          </p>
        </div>
        <div class="meal_item">
          <p class="title">精品套餐(热销)解读：</p>
          <p class="desc">
            于关键词的选取，我们会根据您的产品或行业具体情况而制定相对应的关键词库。
          </p>
        </div>
      </div>
      <img src="../assets/img_graphics_a.png" alt="" />
    </div>
    <div class="section_box cooperation_process">
      <div class="page_center page_title">
        整站优化合作流程 · COOPERATION PROCESS
      </div>
      <div class="page_center process_box">
        <div class="process_item">
          <img
            class="process_image"
            src="../assets/ic_optimization_a.png"
            alt=""
          />
          <span>联系腾创互联客服</span>
        </div>
        <div class="arrow_icon"></div>
        <div class="process_item">
          <img
            class="process_image"
            src="../assets/ic_optimization_b.png"
            alt=""
          />
          <span>关键词查询下单</span>
        </div>
        <div class="arrow_icon"></div>
        <div class="process_item">
          <img
            class="process_image"
            src="../assets/ic_optimization_c.png"
            alt=""
          />
          <span>签合同付款项</span>
        </div>
        <div class="arrow_icon"></div>
        <div class="process_item">
          <img
            class="process_image"
            src="../assets/ic_optimization_d.png"
            alt=""
          />
          <span>开始网站优化</span>
        </div>
        <div class="arrow_icon"></div>
        <div class="process_item">
          <img
            class="process_image"
            src="../assets/ic_optimization_e.png"
            alt=""
          />
          <span>关键词上首页</span>
        </div>
      </div>
    </div>

    <z-footer></z-footer>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
    components: {
      swiper,
      swiperSlide,
    },
  data() {
    return {
      caseSwiperOption: {
        slidesPerView: 3,
        // centeredSlides: true,
        spaceBetween: 20,
        navigation: {
          nextEl: ".case-swiper-button-next",
          prevEl: ".case-swiper-button-prev",
        },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.banner_image_box {
  height: 500px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.section_box {
  padding-top: 80px;
}

.page_title {
  font-size: 32px;
  color: #333333;
  text-align: center;
  &.white {
    color: #fff;
  }
}

.service_introduction {
  overflow: hidden;
  position: relative;
  height: 357px;

  .page_center {
    z-index: 2;
    position: relative;
  }

  .desc {
    width: 700px;
    margin: 80px auto;
    font-size: 14px;
    color: #333333;
    line-height: 28px;
    text-indent: 2em;
  }

  .circular {
    position: absolute;
    border: 80px solid #fbf9ff;
    border-radius: 50%;

    &.circular1 {
      width: 400px;
      height: 400px;
      left: 320px;
      bottom: 0;
      transform: translateY(50%);
    }
    &.circular2 {
    }
  }
}

.four_stages {
  height: 606px;
  background: #fbf9ff;

  .stages_box {
    display: flex;
    margin-top: 80px;

    .stages_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 319px;
      height: 324px;
      background: #fff;
      color: #333333;

      .title {
        font-size: 18px;
        font-weight: 600;
        margin: 40px 0;
      }

      .desc {
        width: 239px;
        font-size: 12px;
        line-height: 24px;
      }

      & + .stages_item {
        margin-left: 2px;
      }
    }

    .stages_image {
      width: 60px;
      height: 60px;
    }
  }
}

.annual_package {
  height: 948px;
  .set_meal_box {
    margin-top: 80px;

    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 59px;
      background: #fbf9ff;
      color: #333333;
      font-size: 14px;
      line-height: 24px;

      & + .item {
        margin-top: 2px;
      }

      &.version {
        height: 120px;
      }

      &.header {
        width: 319px;
        height: 88px;
        background-color: #6f40f3;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }

      &.price {
        height: 486px;
        .btn {
          width: 199px;
          height: 42px;
          background-color: #f3b740;
          border-radius: 2px;
          color: #ffffff;
        }
      }
    }

    .box_body {
      display: flex;
      .box_col {
        width: 319px;

        & + .box_col {
          margin-left: 2px;
        }
      }
    }

    .box_footer {
      margin-top: 2px;

      .item {
        justify-content: left;
        height: 88px;
        padding-left: 80px;
      }
    }
  }
}

.success_case {
  height: 715px;
  background: #fbf9ff url("../assets/bg_a.png") no-repeat center;

  .case_list {
    margin-top: 80px;

    .case_item {
      position: relative;
      width: 414px;
      height: 433px;
      padding: 40px;
      background-color: #ffffff;
      border-radius: 20px;

      .item_footer {
        display: flex;
        justify-content: space-between;
        position: absolute;
        left: 0;
        bottom: 40px;
        width: 100%;
        padding: 0 40px;
        color: #333333;

        .red {
          color: #ff3030;
        }

        .more {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
      }
    }

    .case_name {
      margin-bottom: 40px;
      color: #333333;
      font-size: 18px;
      font-weight: 600;
      text-align: center;
    }

    .word_box {
      .word_title {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
      }

      .word_list {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
      }

      .word_item {
        padding: 0 12px;
        margin: 0 10px 10px 0;
        border-radius: 14px;
        border: solid 1px #e5e5e5;
        line-height: 28px;
        font-size: 12px;
      }

      & + .word_box {
        margin-top: 10px;
      }
    }
  }
}

.meal_interpret {
  display: flex;
  justify-content: space-between;
  height: 626px;

  .interpret_box {
    width: 465px;
    padding-top: 47px;
    line-height: 24px;
    color: #333333;

    .meal_item + .meal_item {
      margin-top: 60px;
    }

    .title {
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 600;
    }
  }

  img {
    width: 514px;
    height: 466px;
  }
}

.cooperation_process {
  height: 405px;
  background-color: #fbf9ff;
  .process_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 80px;

    .process_item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .process_image {
      width: 80px;
      height: 80px;
      margin-bottom: 24px;
    }
  }
}

.swiper_case_box {
  margin-top: 40px;
  height: 264px;
  position: relative;

  .case-swiper-button {
    position: absolute;
    top: 50%;
    margin-top: -20px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #222222;

    &.white {
      background: #fff;
    }

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 12px;
      height: 12px;
      background: transparent;
      border: 2px solid transparent;
    }
  }

  .case-swiper-button-prev {
    left: -100px;

    &::after {
      margin-left: 6px;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(45deg);
      border-left-color: #fff;
      border-bottom-color: #fff;
    }

    &.white::after {
      border-left-color: #222222;
      border-bottom-color: #222222;
    }
  }

  .case-swiper-button-next {
    right: -100px;

    &::after {
      margin-left: -6px;
      transform-origin: center;
      transform: translate(-50%, -50%) rotate(-45deg);
      border-right-color: #fff;
      border-bottom-color: #fff;
    }

    &.white::after {
      border-right-color: #222222;
      border-bottom-color: #222222;
    }
  }
}

.arrow_icon {
  display: block;
  width: 12px;
  height: 8px;
  background: url("../assets/ic_to.png") 100% 100%;
}
</style>